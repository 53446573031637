import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import TutorialPage from "../../../../components/page-templates/tutorial/"

export const query = graphql`
  query RightsTutorialPage {
    pageData: wpPage(
      slug: {
        eq: "historiska-perspektiv_utan-demokratiska-rattigheter_handledning"
      }
    ) {
      ...TutorialFields
    }
  }
`

const RightsIntro = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          Handledning | Utan Demokratiska Rättigheter | Demokrati Pågår{" "}
        </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/handledning"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/handledning"
        ></meta>
        <meta property="og:title" content="Handledning"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <TutorialPage {...props} />
    </>
  )
}

export default RightsIntro
